<template>
   <div class="max-w-full print:max-w-4xl mt-10">
        <div class="flex justify-center items-center print:hidden">
            <button type="button" onclick="window.print()" class="px-3 py-2 rounded bg-gray-500 text-white print:hidden">Print</button>
        </div>
        <div class="max-w-4xl mx-auto">
            <div class="flex flex-col items-center justify-center">
                <span class="text-4xl">GCPSC</span>
                <span class="text-xl print:text-md">Assign Mentor: {{  assignMentor.name }} </span>
                <span class="text-xl print:text-md">Mentor Phone: {{  assignMentor.phone }} </span>
            </div>
            <div class="flex justify-between mt-10">
                <div class="text-left">
                    <div class="text-md print:text-md">Patient Name : {{  appointment.patientName }} </div>
                    <div class="text-sm print:text-sm">Support Type : {{  appointment.type }} </div>
                    <div class="text-sm print:text-sm">Phone : {{  appointment.patientPhone }} </div>
                </div>
                <div class="text-left">
                    <div class="text-md print:text-md">Appointment Date : {{  schedule.date }} </div>
                    <div class="text-sm print:text-md">Appointment Time : {{  time }} </div>
                </div>
            </div>
            <div>
            <div class="p-2 text-sm mt-4">
                <div class="relative overflow-x-auto shadow-md print:shadow-none sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                SL
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Question
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-white border-b" v-for="(question, index) in appointment.questions" :key="index" >
                            <td class="px-6 py-4">
                                {{ ++index }}
                            </td>
                            <td class="px-6 py-4">
                                {{ question }}
                            </td>
                        </tr>
                        
                    
                    </tbody>
                </table>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>

export default({
   name:'question-print',
   components:{},
   created(){
     this.$store.dispatch('adminAppointment/questionPrint',{
        appointmentId: this.appointmentId
     });
   },
   computed:{
        printInfo(){
            return  this.$store.state.adminAppointment.question_print || '';
        },
        appointment(){
            return this.printInfo.appointment || '';
        },
        assignMentor(){
            return this.appointment.assigned_mentor || '';
        },
        schedule(){
            return this.printInfo.schedule || '';
        },
        time(){
            const time = this.schedule.time_schedule || '';
            const startTime = time.start_time;
            const endTime = time.end_time;
            return `${startTime} - ${endTime}`;

        },
        appointmentId(){
            return this.$route.params.appointmentId
        }
   }
})
</script>
